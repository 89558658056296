import React from "react";
import {preloadImages} from './js/utils.js';
import {Grid} from './js/grid.js';
import "../../App.css";
import "./topCities.css";
import "./css/importImages.css";
import "./js/index";
import ChatBot from "../Chatbot/Chatbot";

function TopCities() {

// document.documentElement.className="js";var supportsCssVars=function(){var e,t=document.createElement("style");return t.innerHTML="root: { --tmp-var: bold; }",document.head.appendChild(t),e=!!(window.CSS&&window.CSS.supports&&window.CSS.supports("font-weight","var(--tmp-var)")),t.parentNode.removeChild(t),e};supportsCssVars()||alert("Please view this demo in a modern browser that supports CSS Variables.");
// Preload images then remove loader (loading class) from body
    preloadImages('.column__item-img').then(() => {
        document.body.classList.remove('loading');

        // Initialize the grid
        new Grid(document.querySelector('.columns'));
    });

    return (
        <>
            <div id="storyIdeas" style={{marginTop: "50px"}}>
                <div className="frame" style={{maxWidth: "20px"}}>
                    <p className="frame__title">Top 50 Digital Nomad Destinations</p>
                </div>

                {/*Images 1-17*/}
                <div className="columns story-img-fadein" data-scroll-container="">
                    <div className="column-wrap column-wrap--height">
                        <div className="column">

                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={2}>
                                    <div className="column__item-img img1">
                                        <figcaption className="caption">
                                            <p className="center">Lisbon<br/><span className="caption2">Portugal</span>
                                            </p>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>

                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={5}>
                                    <div className="column__item-img img2">
                                        <figcaption className="caption">
                                            <p className="center">Chiang Mai<br/><span
                                                className="caption2">Thailand</span>
                                            </p>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={8}>
                                    <div className="column__item-img img3">
                                        <figcaption className="caption">
                                            <p className="center">Bali<br/><span className="caption2">Indonesia</span>
                                            </p>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={11}>
                                    <div className="column__item-img img4">
                                        <figcaption className="caption">
                                            <p className="center">Medellin<br/><span
                                                className="caption2">Colombia</span>
                                            </p>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={14}>
                                    <div className="column__item-img img5">
                                        <figcaption className="caption">
                                            <p className="center">Ho Chi Minh City<br/><span
                                                className="caption2">Vietnam</span></p>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={17}>
                                    <div className="column__item-img img6">
                                        <figcaption className="caption">
                                            <p className="center">Ubud<br/><span
                                                className="caption2">Indonesia</span></p>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={20}>
                                    <div className="column__item-img img7">
                                        <figcaption className="caption">
                                            <p className="center">Nafplio<br/><span className="caption2">Greece</span>
                                            </p>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={23}>
                                    <div className="column__item-img img8">
                                        <figcaption className="caption">
                                            <p className="center">Da Nang<br/><span
                                                className="caption2">, Vietnam</span></p>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={26}>
                                    <div className="column__item-img img9">
                                        <figcaption className="caption">
                                            <p className="center">Playa del Carmen<br/><span
                                                className="caption2">Mexico</span></p>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={29}>
                                    <div className="column__item-img img10">
                                        <figcaption className="caption">
                                            <p className="center">Ljubljana<br/><span
                                                className="caption2">Slovenia</span></p>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                        </div>
                    </div>

                    <div className="column-wrap">
                        <div className="column" data-scroll-section="">
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={3}>
                                    <div className="column__item-img img11">
                                        <figcaption className="caption">
                                            <p className="center">Valletta<br/><span className="caption2">Malta</span>
                                            </p>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={6}>
                                    <div className="column__item-img img12">
                                        <figcaption className="caption">
                                            <p className="center">Saint Petersburg<br/><span
                                                className="caption2">Russia</span></p>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={9}>
                                    <div className="column__item-img img13">
                                        <figcaption className="caption">
                                            <p className="center">Kuala Lumpur<br/><span
                                                className="caption2">Malaysia</span></p>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={12}>
                                    <div className="column__item-img img14">
                                        <figcaption className="caption">
                                            <p className="center">Willemstad<br/><span
                                                className="caption2">Curaçao</span>
                                            </p>
                                        </figcaption>
                                    </div>
                                </div>

                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={15}>
                                    <div className="column__item-img img15">
                                        <figcaption className="caption">
                                            <p className="center">Budapest<br/><span className="caption2">Hungary</span>
                                            </p>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={18}>
                                    <div className="column__item-img img16">
                                        <figcaption className="caption">
                                            <p className="center">Penang<br/><span
                                                className="caption2">Malaysia</span></p>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={21}>
                                    <div className="column__item-img img17">
                                        <figcaption className="caption">
                                            <p className="center">San Sebastián<br/><span
                                                className="caption2">Spain</span></p>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={24}>
                                    <div className="column__item-img img18">
                                        <figcaption className="caption">
                                            <p className="center">Koh Lanta<br/><span
                                                className="caption2">Thailand</span>
                                            </p>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={27}>
                                    <div className="column__item-img img19">
                                        <figcaption className="caption">
                                            <p className="center">Antigua<br/><span
                                                className="caption2">Guatemala</span>
                                            </p>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={30}>
                                    <div className="column__item-img img20">
                                        <figcaption className="caption">
                                            <p className="center">Tbilisi<br/><span
                                                className="caption2">Georgia</span></p>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                        </div>
                    </div>

                    {/*Third Column*/}
                    <div className="column-wrap column-wrap--height">
                        <div className="column">
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={1}>
                                    <div className="column__item-img img21">
                                        <figcaption className="caption">
                                            <p className="center">Chiang Rai<br/><span
                                                className="caption2">Thailand</span></p>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={4}>
                                    <div className="column__item-img img22">
                                        <figcaption className="caption">
                                            <p className="center">Hoi An<br/><span className="caption2">Vietnam</span>
                                            </p>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={7}>
                                    <div className="column__item-img img23">
                                        <figcaption className="caption">
                                            <p className="center">Nusa Lembongan<br/><span
                                                className="caption2">Indonesia</span>
                                            </p>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={10}>
                                    <div className="column__item-img img24">
                                        <figcaption className="caption">
                                            <p className="center">El Tunco<br/><span
                                                className="caption2">El Salvador</span>
                                            </p>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={13}>
                                    <div className="column__item-img img25">
                                        <figcaption className="caption">
                                            <p className="center">Cape Town<br/><span
                                                className="caption2">South Africa</span></p>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={16}>
                                    <div className="column__item-img img26">
                                        <figcaption className="caption">
                                            <p className="center">Da Lat<br/><span className="caption2">Vietnam</span>
                                            </p>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={19}>
                                    <div className="column__item-img img27">
                                        <figcaption className="caption">
                                            <p className="center">Santa Teresa<br/><span
                                                className="caption2">Costa Rica</span>
                                            </p>
                                        </figcaption>
                                    </div>
                                </div>

                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={22}>
                                    <div className="column__item-img img28">
                                        <figcaption className="caption">
                                            <p className="center">Boquete<br/><span
                                                className="caption2">Panama</span></p>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={25}>
                                    <div className="column__item-img img29">
                                        <figcaption className="caption">
                                            <p className="center">Lagos<br/><span className="caption2">Portugal</span>
                                            </p>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={28}>
                                    <div className="column__item-img img30">
                                        <figcaption className="caption">
                                            <p className="center">Bellingham, WA<br/><span
                                                className="caption2">USA</span>
                                            </p>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                        </div>
                    </div>
                </div>

                {/*Written Descriptions/Content*/}
                <div className="content">
                    <div className="content__item">
                        <section className="content__item-text">
                            <div className="white-background">Chiang Rai, nestled in the verdant mountains of northern Thailand, offers a serene
                                alternative to the bustling energy of Bangkok or the tourist crowds of Phuket. Here, the
                                pace of life slows down, allowing digital nomads to find a peaceful focus for their
                                work. The cost of living is significantly lower compared to southern Thailand. There's
                                also the artistic heritage at the renowned White Temple (Wat Rong Khun) and the history
                                of the Lanna Kingdom at the National Museum. Seek tranquility
                                in the lush surrounds of Doi Mae Salong, a village known for its breathtaking mountain
                                views and unique blend of Thai and Chinese cultures. After dark, Chiang Rai offers a
                                slower pace, with charming night markets and local restaurants serving up delicious
                                northern Thai cuisine.
                            </div>
                        </section>
                    </div>

                    <div className="content__item">
                        <section className="content__item-text">
                            <div>Forget overpriced European capitals – Lisbon offers a budget-friendly alternative that
                                lets you hold onto more euros. The city's got your back on the work front too, with
                                blazing-fast internet and a booming co-working scene. Ditch the isolation of cafes and
                                connect with other digital nomads in these shared workspaces. But Lisbon isn't all
                                spreadsheets and deadlines. This city's steeped in history, boasts a killer food scene,
                                and throws in stunning beaches for good measure. It's a solid option for remote workers
                                who want to be productive and explore without sacrificing their bank account.

                            </div>
                        </section>
                    </div>

                    <div className="content__item">
                        <section className="content__item-text">
                            <div>Valletta ditches the digital nomad stereotype of sandy beaches. This historic city
                                prioritizes culture over cocktails. Your Euros stretch
                                further here, but the real
                                perk is the chance to work amidst living history. Valletta isn't just work and stunning
                                harbor views (although those are postcard-worthy for sure). Explore the magnificent
                                Baroque architecture, like St. John's Co-Cathedral, adorned with Caravaggio
                                masterpieces. Delve into Valletta's rich past at the National Museum of Archaeology, or
                                stroll the fortified city walls for panoramic vistas. It's the perfect blend for digital
                                nomads seeking a unique European escape: affordability, strong wifi, and an
                                unforgettable Maltese adventure steeped in history and charm.
                            </div>
                        </section>
                    </div>
                    <div className="content__item">
                        <section className="content__item-text">
                            <div>Unlike the frenetic energy of
                                Ho Chi Minh City, Hoi An offers a more relaxed pace, allowing you to focus on work
                                amidst a captivating atmosphere. It is nestled amidst rice fields and is a picturesque
                                backdrop
                                with the iconic Japanese Covered Bridge and colorful ancient town. Explore
                                the town's rich history, wandering through the narrow streets lined with traditional
                                tailor shops and vibrant art galleries. For a taste of adventure, take a bike ride
                                through the surrounding countryside, dotted with rice paddies and quaint villages. In
                                the evenings, Hoi An comes alive with the glow of lanterns, casting a magical spell
                                as you explore the vibrant night market or enjoy fresh seafood on the Thu Bon River.
                            </div>
                        </section>
                    </div>
                    <div className="content__item">
                        <section className="content__item-text">
                            <div>This northern Thai
                                city caters to remote workers with a laser focus on value. Living costs here are dirt
                                cheap compared to other hotspots, leaving more baht in your pocket. The internet? Rock
                                solid. Co-working spaces are plentiful, offering an
                                escape from endless cups of cafe coffee and a chance to connect with fellow digital
                                nomads. But Chiang Mai isn't all work and sticky rice. This ancient city offers culture,
                                with stunning temples and a vibrant local scene. Plus, there's some killer hiking and
                                exploring in the surrounding mountains if you need a break from the screen. Chiang Mai
                                cuts to the chase: affordable living, strong wifi, and a chance to ditch the laptop and
                                delve into Thai history.
                            </div>
                        </section>
                    </div>
                    <div className="content__item">
                        <section className="content__item-text">
                            <div>This grand dame of Russian cities offers a potent mix of history and culture, all at a
                                budget-friendly price. Forget sky-high rents – your rubles stretch further here, fueling
                                hearty lunches and evenings spent sipping strong tea in cozy
                                cafes. Blazing-fast internet and a burgeoning co-working scene ensure you stay
                                connected, but the real perk is the city's unique atmosphere. Saint Petersburg isn't
                                just work and awe-inspiring architecture (although those opulent palaces are a constant
                                source of wonder). Explore the world-renowned
                                Hermitage Museum, housing priceless art collections. Wander through the Peter and Paul
                                Fortress, brimming with Russian history. After dark, immerse yourself in the city's
                                vibrant ballet scene or be captivated by a thrilling performance at the Mariinsky
                                Theatre.
                            </div>
                        </section>
                    </div>
                    <div className="content__item">
                        <section className="content__item-text">
                            <div>Nusa Lembongan, a small island gem off Bali, beckons digital nomads with a unique blend
                                of work and island escape. Reliable internet lets you stay connected in
                                guesthouses, cafes, or co-working spaces, though it's best to manage expectations – Nusa
                                Lembongan's infrastructure is still developing. Workdays transform into afternoons spent
                                unwinding on pristine beaches, exploring hidden coves, or diving into vibrant coral
                                reefs. Day trips to nearby islands and a laid-back nightlife scene with fresh seafood
                                and live music round
                                out the experience. Nusa Lembongan provides an affordable Indonesian escape for
                                digital nomads seeking an idyllic blend of stunning scenery and the
                                ability to get work done in paradise.
                            </div>
                        </section>
                    </div>
                    <div className="content__item">
                        <section className="content__item-text">
                            <div>Bali ditches the European price tag for digital nomads. Dirt-cheap living in this
                                Indonesian island paradise frees up rupiah for essentials. Blazing-fast internet and
                                co-working spaces keep you connected, ditching the cafe grind. But Bali isn't just work
                                and sunsets. Stunning beaches, world-class diving, and a rich cultural scene fuel
                                exploration. For yogis, daily classes abound, while adventurous souls can tackle
                                volcanoes or trek through rice paddies. Bali's nightlife throbs with energy, from beach
                                bars to pulsating clubs. It's the trifecta digital nomads crave: affordable living,
                                strong wifi, and an unforgettable Indonesian adventure.
                            </div>
                        </section>
                    </div>
                    <div className="content__item">
                        <section className="content__item-text">
                            <div>Forget sleepy Southeast Asian towns – KL pulses with energy, offering towering
                                skyscrapers alongside buzzing street food stalls. Your Malaysian Ringgit goes a long way
                                here, fueling you with plates of satay (skewered meats) and frothy teh tarik (milk tea).
                                Blazing-fast internet and a thriving co-working scene ditch the cafe grind and connect
                                you with a network of fellow remote workers. But KL isn't just work and dazzling
                                cityscapes with the iconic Petronas Twin Towers. Explore the bustling night markets or
                                hike through the lush greenery of Kuala Lumpur Bird Park. KL offers the perfect blend of
                                affordability, strong wifi, and an unforgettable Malaysian adventure.
                            </div>
                        </section>
                    </div>
                    <div className="content__item">
                        <section className="content__item-text">
                            <div>El Tunco, El Salvador, carves out a niche for digital nomads seeking a Central American
                                adventure that balances productivity with world-class surfing. Reliable internet and a
                                growing co-working scene
                                ensure you stay connected, but the real perk is El Tunco's infectious beach vibe.
                                Explore the charming town overflowing with surf shops and local eateries, or conquer the
                                waves that made El Tunco famous. After dark, the energy explodes with live music
                                spilling from beach bars, offering a taste of the Salvadoran nightlife. El Tunco ditches
                                the high-priced tourist havens for affordability, strong wifi, and an unforgettable
                                Salvadoran adventure amidst a surfer's paradise.
                            </div>
                        </section>
                    </div>
                    <div className="content__item">
                        <section className="content__item-text">
                            <div>Medellin ditches the digital nomad cliche. Forget beach bumming – Medellin's all about
                                innovation and affordability. Your pesos stretch further here, fueling your Colombian
                                coffee habit and leaving room for delicious local eats. There's also blazing-fast
                                internet and a
                                booming co-working scene. But Medellin isn't just work and stunning mountain views
                                (though those are epic
                                for after-work hikes). Soar above the city on the Metrocable, a network of cable cars
                                that started as public transportation and became a tourist attraction. Explore
                                Medellin's
                                history at the Museo Casa de la Memoria, a powerful exploration of Colombia's past. It's
                                a city that blends affordability, strong wifi, and a unique Colombian adventure unlike
                                any other.
                            </div>
                        </section>
                    </div>

                    <div className="content__item">
                        <section className="content__item-text">
                            <div>Willemstad, Curaçao, throws a cultural curveball. This UNESCO World Heritage gem bursts
                                with Dutch colonial charm – think candy-colored buildings lining pastel-hued harbors.
                                Fast internet and a growing co-working scene keep you connected, but the real perk is
                                the
                                infectious island rhythm. Willemstad isn't just about work and picture-perfect scenery.
                                Dive into the vibrant Queen Emma Bridge, a pontoon
                                bridge that swings open for passing ships. Explore the Punda district's floating market,
                                a kaleidoscope of colorful produce bobbing on the water. After dark, lose yourself in
                                the pulsating nightlife of Pietermaai, where salsa music spills from open-air bars.
                                Willemstad offers a unique blend for digital nomads.
                            </div>
                        </section>
                    </div>
                    <div className="content__item">
                        <section className="content__item-text">
                            <div>Cape Town, South Africa's "Mother City," beckons digital nomads with a unique blend of
                                urban energy and breathtaking natural beauty. Here, your Rand stretches further, fueling
                                delicious meals and epic adventures. Reliable internet and a thriving co-working scene
                                keep you connected, while the city unfolds its magic after work. Hike or bike Table
                                Mountain, explore Kirstenbosch National Botanical Garden, or delve into Robben Island's
                                history. Wine tours, wildlife safaris, world-class surfing, and a vibrant nightlife
                                scene with trendy bars and live music round out the experience. Cape Town offers an
                                unforgettable blend of work, relaxation, and adventure in a city that pulsates with
                                life.
                            </div>
                        </section>
                    </div>
                    <div className="content__item">
                        <section className="content__item-text">
                            <div>Ho Chi Minh City throws down the gauntlet for budget-minded digital nomads.
                                Forget sky-high rents – here, your Vietnamese Dong goes a long way, fueling delicious
                                street food and endless cups of strong coffee. Blazing-fast internet
                                and a thriving co-working scene connect you with a network of
                                fellow remote workers. But Ho Chi Minh City isn't just work and bustling cityscapes.
                                Explore the city's rich history at the Cu Chi Tunnels, a network of underground passages
                                used by the Viet Cong. Immerse yourself in the vibrant markets of Ben Thanh, where you
                                can snag unique souvenirs. And for a dose of nature, escape to the Mekong Delta, a
                                labyrinth of canals and rice paddies. It's the perfect blend for digital nomads:
                                affordability, strong wifi, and an unforgettable Vietnamese adventure.
                            </div>
                        </section>
                    </div>
                    <div className="content__item">
                        <section className="content__item-text">
                            <div>This captivating city on the Danube River prioritizes historic charm over five-star
                                resorts. Your Hungarian forint stretches further here, fueling you with steaming bowls
                                of langos (fried dough) and rich Hungarian hot chocolate. Blazing-fast internet and a
                                booming co-working scene ditch the cafe grind and connect you with a network of fellow
                                remote workers. But Budapest isn't just work and awe-inspiring architecture. Explore the
                                hidden
                                depths of Buda Castle through labyrinthine streets, or descend into the fascinating
                                Budapest Metro, one of the oldest underground systems in the world. After dark, immerse
                                yourself in the city's thermal baths, a historical tradition offering relaxation for
                                body and soul.
                            </div>
                        </section>
                    </div>
                    <div className="content__item">
                        <section className="content__item-text">
                            <div>Nestled amidst misty mountains, Da Lat, Vietnam, offers a cool escape for digital
                                nomads seeking a unique blend of French colonial charm and Vietnamese culture. The cost
                                of living here is lower than Vietnam's bustling coastal cities. Reliable internet and a
                                growing network of
                                co-working spaces keep you connected. But the real perk is Da Lat's idyllic climate and
                                laid-back atmosphere. Explore the charming streets lined with French architecture, visit
                                the Linh Phuoc Pagoda, a dazzling temple adorned with mosaics, or hike through scenic
                                trails amidst waterfalls and pine forests. This unique city offers affordability, strong
                                wifi, and
                                an unforgettable Vietnamese adventure that mixes work with cultural immersion and a
                                touch of European flair.
                            </div>
                        </section>
                    </div>
                    <div className="content__item">
                        <section className="content__item-text">
                            <div>Ubud ditches the digital nomad stereotype of sandy beaches. This enchanting town
                                nestled in Bali's lush interior prioritizes tranquility over tanning. Dirt-cheap living
                                lets you indulge in daily yoga sessions or traditional Balinese massages. Blazing-fast
                                internet and a blossoming co-working scene keep you connected, but ditch the usual cafe
                                routine. Explore ancient temples like the Monkey Forest Sanctuary, teeming with
                                mischievous primates. Ubud's heart beats with art – immerse yourself in galleries
                                showcasing local crafts or take a batik-making workshop. It's the perfect blend for
                                digital nomads seeking a soulful escape: affordable living, strong wifi, and an
                                unforgettable Balinese experience steeped in culture and wellness.
                            </div>
                        </section>
                    </div>
                    <div className="content__item">
                        <section className="content__item-text">
                            <div>This UNESCO heritage city lets you ditch the overpriced cafes and co-working spaces for
                                a vibrant mix of street food culture and charming colonial architecture. Your Malaysian
                                Ringgit stretches far. Reliable internet and a growing co-working scene
                                keep you connected, but the real perk is the city's relaxed pace and friendly
                                atmosphere. Penang isn't just work and stunning beaches (although those are a definite
                                bonus). Explore the vibrant Georgetown district, a melting pot of Chinese, Malay, and
                                Indian influences. Hike Penang Hill for panoramic views, or delve into history at the
                                Kek Lok Si Temple, one of Southeast Asia's largest Buddhist temples.
                                Malaysian adventure that mixes cultural exploration with a relaxed island vibe.
                            </div>
                        </section>
                    </div>
                    <div className="content__item">
                        <section className="content__item-text">
                            <div>Here, the focus shifts from pounding the pavement to pounding the waves. While the cost
                                of living might be slightly higher than other parts of the country, reliable internet
                                lets you stay connected from beachfront cafes. But the real perk is Santa Teresa's
                                bohemian charm. Explore the
                                jungle paths lined with surf shops and yoga studios, or conquer the world-class waves
                                that keep surfers flocking here. After dark, the vibe gets mellow with live music
                                spilling from beach bars, offering a taste of the Tico nightlife. Santa Teresa ditches
                                the crowded resorts for affordability, solid wifi, and an unforgettable Costa Rican
                                adventure.
                            </div>
                        </section>
                    </div>
                    <div className="content__item">
                        <section className="content__item-text">
                            <div>Nafplio, Greece, skips the digital nomad herd mentality.
                                Forget crowded co-working spaces – here, history whispers from Venetian castles and
                                charming squares become your open-air office. Affordable living lets you indulge in
                                delicious Greek mezze spreads and strong local coffee. Reliable internet keeps you
                                connected, but the real perk is the community. Nafplio isn't just work and breathtaking
                                sea views (although those are Instagram-worthy). Explore ancient ruins like Palamidi
                                fortress, a hike with panoramic rewards. Immerse yourself in Greek mythology by
                                strolling along the harbor where legend says Hercules once docked. Nafplio offers a
                                unique blend for digital nomads: affordability, strong wifi, and an unforgettable Greek
                                adventure steeped in history and charm.
                            </div>
                        </section>
                    </div>
                    <div className="content__item">
                        <section className="content__item-text">
                            <div>San Sebastián ditches the
                                Barcelona price tag and offers a solid euro stretch for your essentials. Reliable
                                internet and a co-working scene on the rise keep you
                                connected. But San Sebastián is all about the lifestyle, not
                                spreadsheets and sunsets. Explore the charming maze of the Parte Vieja, a pintxo
                                paradise, or hike Monte Urgull for epic city views. After dark, the city's nightlife
                                throbs, from buzzing bars to traditional cider houses. San Sebastián cuts to the chase:
                                affordable living, strong wifi, and an unforgettable Basque adventure for remote workers
                                who prioritize good food, good vibes, and a taste of local culture.
                            </div>
                        </section>
                    </div>
                    <div className="content__item">
                        <section className="content__item-text">
                            <div>Boquete, Panama, nestled in cloud forests, offers a digital nomad haven with a
                                refreshing climate, stunning scenery, and a laid-back vibe. Reliable internet and
                                co-working
                                spaces keep you connected. Beyond work, Boquete unfolds its magic:
                                hike lush trails, explore Baru Volcano National Park, or try white-water rafting. The
                                charming town boasts a vibrant ex-pat community, craft breweries, sidewalk cafes, and a
                                weekly market. Evenings bring live music and friendly gatherings, making Boquete an
                                unforgettable blend of breathtaking scenery and relaxed pace for remote
                                work in a natural paradise.
                            </div>
                        </section>
                    </div>
                    <div className="content__item">
                        <section className="content__item-text">
                            <div>Forget sleepy beach towns – Da Nang pulsates with energy, offering stunning beaches
                                alongside a modern cityscape. Your Vietnamese Dong stretches far here. Blazing-fast
                                internet and a booming
                                co-working scene ditch the cafe grind and connect you with a network of fellow remote
                                workers. But Da Nang isn't just work and epic views. Hike the dramatic Marble Mountains,
                                a cluster of Buddhist temples carved into
                                cliffs. Explore the captivating ruins of My Son, an ancient Hindu temple complex. Or,
                                for a dose of adrenaline, try surfing the world-class waves off My Khe Beach. Da Nang
                                offers the perfect blend for digital nomads: affordability, strong wifi, and an
                                unforgettable Vietnamese adventure that mixes relaxation, exploration, and a touch of
                                adventure.
                            </div>
                        </section>
                    </div>
                    <div className="content__item">
                        <section className="content__item-text">
                            <div>Forget the party islands of Phi Phi. Koh Lanta, Thailand, offers a more grounded
                                digital nomad experience. Here, your baht stretches further, fueling you on delicious
                                pad thai and fresh mango sticky rice. Co-working spaces are popping up like palm trees,
                                letting you ditch the endless cafe grind. Explore the lush jungles on a motorbike, or
                                dive into the crystal-clear waters teeming
                                with marine life. After dark, the pace slows down, with laid-back beach bars and fresh
                                seafood restaurants offering a taste of the island's chilled vibes. Koh Lanta offers
                                affordable living, good wifi, great food, and an unforgettable Thai
                                adventure for those who want to truly unwind.
                            </div>
                        </section>
                    </div>
                    <div className="content__item">
                        <section className="content__item-text">
                            <div>Lagos, Portugal, swaps the Algarve's usual price tag for a more digital nomad-friendly
                                budget. Super-fast internet and a blossoming co-working scene ensure you stay
                                connected and ditch the cafe grind. Explore the vibrant maze of the Parte Vieja, a
                                paradise for pintxos (Basque tapas) lovers. Hike up Monte Burgau for epic city views, or
                                kayak through hidden coves. After dark, the city comes alive with buzzing bars and
                                traditional fado houses, offering a taste of Portuguese nightlife. Lagos offers the
                                perfect digital nomad blend: affordability, strong wifi, and an unforgettable Portuguese
                                adventure that mixes productivity with cultural immersion and a touch of coastal charm.
                            </div>
                        </section>
                    </div>
                    <div className="content__item">
                        <section className="content__item-text">
                            <div>Forget remote co-working spaces – here, vibrant cafes and beach clubs transform into
                                your office with a side of sunshine. Affordable living lets you indulge in fresh tacos
                                and icy margaritas. Blazing-fast internet keeps you connected, but the real perk is the
                                ever-present buzz of fellow remote workers and friendly locals. Playa del Carmen isn't
                                just work and picture-perfect beaches (although those are a daily perk). Dive into the
                                dazzling underwater world of the Mayan Riviera or explore ancient ruins like Tulum.
                                After dark, 5th Avenue explodes with energy, offering a taste of Mexico's nightlife
                                scene. It's the perfect blend for social digital nomads: affordability, strong wifi, and
                                an unforgettable Mexican adventure.
                            </div>
                        </section>
                    </div>
                    <div className="content__item">
                        <section className="content__item-text">
                            <div>Antigua ditches the Cancun crowds for digital nomads seeking a taste of Central
                                American charm. Forget sky-high prices – your Guatemalan quetzals stretch further here,
                                fueling you with delicious fresh tamales and strong Guatemalan coffee. This isn't just
                                about work and postcard-perfect volcanoes. Explore the charming
                                cobblestone streets lined with colorful buildings, or delve into the city's rich history
                                at the ruins of Antigua Guatemala, a UNESCO World Heritage Site. Hike the slopes of
                                Pacaya volcano for breathtaking panoramas, or soak in the natural beauty of nearby hot
                                springs.
                            </div>
                        </section>
                    </div>
                    <div className="content__item">
                        <section className="content__item-text">
                            <div>Bellingham, Washington, nestled near the Canadian border, offers a unique blend of
                                stunning natural beauty and a thriving digital nomad scene. Here, the cost of living is
                                lower than other US cities, fueling adventures like kayaking in the bay or exploring the
                                nearby San Juan Islands. Reliable internet and a growing co-working scene ensure you
                                stay connected, while the city itself pulsates with a relaxed Pacific Northwest vibe.
                                Hike or bike through lush forests, explore the vibrant arts scene, or simply soak up the
                                beauty of the surrounding mountains. Bellingham is a haven for remote workers who crave
                                a lifestyle that seamlessly integrates work with outdoor adventure.
                            </div>
                        </section>
                    </div>
                    <div className="content__item">
                        <section className="content__item-text">
                            <div>Ljubljana ditches the digital nomad frenzy. Forget co-working crowds and overpriced
                                cafes. This charming, walkable city transforms into your open-air office. Reliable
                                internet keeps you
                                connected, but the real perk is the city's relaxed vibe and friendly locals. Ljubljana
                                isn't just work and fairytale architecture (though those picturesque bridges and baroque
                                buildings inspire creativity). Explore the sprawling Ljubljana Castle for panoramic
                                views, or take a boat ride down the Ljubljanica River. Immerse yourself in the vibrant
                                cultural scene with world-class museums and charming local galleries. Ljubljana is
                                affordable compared to other European cities,
                                strong wifi, and an unforgettable Slovenian adventure.
                            </div>
                        </section>
                    </div>
                    <div className="content__item">
                        <section className="content__item-text">
                            <div>Tbilisi, Georgia's capital city, offers a unique blend of history, culture, and
                                affordability for digital nomads. The cost of living is lower than many European
                                hotspots. Reliable internet access and a growing network of co-working spaces, some
                                housed in charming cafes and renovated historical buildings, cater to remote work needs.
                                Beyond work, Tbilisi boasts stunning mountain views and a rich past. Explore the
                                labyrinthine streets of the Old Town, where ancient architecture mingles with quirky
                                cafes or soak in the natural sulfur baths, a local tradition for
                                centuries. The city comes alive at night
                                with vibrant bars featuring traditional music and dance performances.
                            </div>
                        </section>
                    </div>


                    <nav className="content__nav">
                        <div className="content__nav-wrap">
                            <div className="content__nav-item img1"/>
                            <div className="content__nav-item img2"/>
                            <div className="content__nav-item img3"/>
                            <div className="content__nav-item img4"/>
                            <div className="content__nav-item img5"/>
                            <div className="content__nav-item img6"/>
                            <div className="content__nav-item img7"/>
                            <div className="content__nav-item img8"/>
                            <div className="content__nav-item img9"/>
                            <div className="content__nav-item img10"/>
                            <div className="content__nav-item img11"/>
                            <div className="content__nav-item img12"/>
                            <div className="content__nav-item img13"/>
                            <div className="content__nav-item img14"/>
                            <div className="content__nav-item img15"/>
                            <div className="content__nav-item img16"/>
                            <div className="content__nav-item img17"/>
                            <div className="content__nav-item img21"/>
                            <div className="content__nav-item img22"/>
                            <div className="content__nav-item img23"/>
                            <div className="content__nav-item img24"/>
                            <div className="content__nav-item img25"/>
                            <div className="content__nav-item img26"/>
                            <div className="content__nav-item img27"/>
                            <div className="content__nav-item img28"/>
                            <div className="content__nav-item img29"/>
                            <div className="content__nav-item img30"/>
                        </div>
                    </nav>

                    <button className="unbutton button-back">
                        <svg viewBox="0 0 50 9" width="100%">
                            <path d="M0 4.5l5-3M0 4.5l5 3M50 4.5h-77"/>
                        </svg>
                    </button>
                </div>
            </div>
            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
        </>
    )
}

export default TopCities