import React from 'react';

function Footer() {
    // Get current year
    const currentYear = new Date().getFullYear();

    return (
        <footer style={footerStyle}>
            <p style={copyrightStyle}>
                © {currentYear} by Aaron Brown | <a href="https://www.aaron140.com" style={linkStyle}>Visit Website</a>
            </p>
        </footer>
    );
}

// CSS styles
const footerStyle = {
    backgroundColor: 'transparent',
    color: 'rgba(123,113,122,0.63)',
    textAlign: 'center',
    padding: '3.5rem 1.5rem 1rem',
    position: 'relative',
    left: '0',
    bottom: '-75px',
    width: '100%',
    fontFamily: 'Poppins-light',
};

const copyrightStyle = {
    fontSize: '14px',
};

const linkStyle = {
    color: 'rgba(123,113,122,0.63)',
    textDecoration: 'none',
    fontSize: '14px',
    fontFamily: 'Poppins-light'

};

export default Footer;
