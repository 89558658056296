import React, {useState} from "react"
import './GPT.css'
import '../Chatbot/chatbot.css'
import ChatBot from "../Chatbot/Chatbot";
import Footer from "../Footer"

const API_KEY = process.env.REACT_APP_API_KEY;

function Costs() {
    const [userInput, setUserInput] = useState("");
    const [aiResponse, setAiResponse] = useState("");

    document.body.style.backgroundColor = '#CDDBE8';

    async function callOpenAIAPI() {
        const APIBody = {
            "model": "gpt-3.5-turbo-instruct",
            "prompt": "Analyze cost-of-living data for chosen location and in 5 concise, complete sentences, suggest budget breakdowns in housing, food, transportation and give total monthly cost for a single person. Include optional items like utilies, eating out and entertainment if tokens allow it. Place the name of HOUSING, FOOD, TRANSPORTATION in all caps. Do not give a numbered list. Do not give advice on how to save money." + userInput,
            temperature: .6,
            max_tokens: 250,
            top_p: .7,
            frequency_penalty: .2,
            presence_penalty: 0.1,

        }

        await fetch("https://api.openai.com/v1/completions", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + API_KEY
            },
            body: JSON.stringify(APIBody)
        }).then((data) => {
            return data.json();
        }).then((data) => {
            console.log(data);
            setAiResponse(data.choices[0].text.trim());

        });
    }


    return (
        <>
            <div className="costs-background-image"></div>
            <div className='max1024'>
                <section className="AI-container" style={{textAlign: 'center'}}>
                    <div className="AI-page-title">Cost of Living</div>
                    <div className="AI-cat-descriptions">Find the real cost for housing, food, and
                        transportation in your desired city, with a breakdown of your estimated monthly budget.
                    </div>

                    <input className='AI-input'
                           onChange={(e) => setUserInput(e.target.value)}
                           placeholder='Enter a city and/or country here...'
                    />
                    <div>
                        <button className="AI-btn" onClick={callOpenAIAPI}>See Costs</button>
                        {aiResponse !== "" ?
                            <p className="AI-summary">{aiResponse}</p>
                            :
                            null
                        }
                    </div>
                </section>
                <ChatBot
                    position={"robot-icon-position"}
                    scale={"robot robot-scale-icon"}
                    gradient={"robot-radial-gradient-none"}
                    robotText={"robot-text-none"}
                />
            </div>
        </>
    )
}

export default Costs