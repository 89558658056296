import React from "react";
import "../App.css"
import "./home.css";
import {Link} from "react-router-dom";
import ChatBot from "./Chatbot/Chatbot";
import LazyImage from 'react-lazy-blur-image';
import styled from 'styled-components';
import Footer from "./Footer"

function Home() {

    const one = document.getElementById('one');
    const cover = document.getElementById('cover');
    const filling = document.getElementById('filling');
    window.addEventListener('scroll', () => {
        let scrollY = window.scrollY;
        let bottomHeight = window.innerHeight;

        if (scrollY / bottomHeight <= 1) {
            one.style.opacity = '1 - ( scrollY / bottomHeight )';
            cover.style.position = 'fixed';
            filling.style.display = 'none';
        } else {
            cover.style.position = null;
            filling.style.display = 'block';
        }
    });

    const Image = styled.img`
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    `;

    const homeplaceholder = require('./Home/hero-images/paris-preload.avif');

    return (
        <>
            <div className="hero-container">
                <LazyImage
                    placeholder={homeplaceholder}
                    render={(src, style) => <Image src={src} style={style}/>}
                />
                <div className="pic" id="pic1"/>
                <div className="pic" id="pic2"/>
                <div className="pic" id="pic3"/>
                <div className="pic" id="pic4"/>

                <img src="/line1.png" alt="Line 1" className="image-line1" />
                <img src="/line2.png" alt="Line 2" className="image-line2" />
            </div>

            <div className="text-sections" style={{paddingTop: '5em'}}>
                <div className='max1024' style={{paddingBottom: "3em"}}>

                    <h1 className='tagline'>YOUR ROADMAP TO THE DIGITAL
                        NOMAD LIFESTYLE STARTS HERE
                    </h1>

                    <div className="three-to-one">
                        <Link to="/discover" reloadDocument={true}>
                            <img className="home-icon"
                                 src={require('./Home/card-images/card1.png')}
                                 alt=""/>
                            <div className='home-rt home-padding'>
                                <h2 className="home-text">DISCOVER YOUR PLACE</h2>
                                <p className="home-text">Let AI help you find the best places in the world to live and
                                    work remotely.</p>

                            </div>
                        </Link>
                        <Link to="/top-cities" reloadDocument={true}>
                            <img className="home-icon"
                                 src={require('./Home/card-images/card2.png')}
                                 alt=""/>
                            <div className='home-rt home-padding'>
                                <h2 className="home-text">TOP 30 DESTINATIONS</h2>
                                <p className="home-text">30 cities digital nomads rank as the best places to live and
                                    work.
                                </p>
                            </div>
                        </Link>
                        <Link to="/costs" reloadDocument={true}>
                            <img className="home-icon"
                                 src={require('./Home/card-images/card3.png')}
                                 alt=""/>
                            <div className='home-rt home-padding'>
                                <h2 className="home-text">COST OF LIVING</h2>
                                <p className="home-text">Find affordable destinations and compare expenses to plan your
                                    budget.
                                </p>
                            </div>
                        </Link>

                    </div>

                    {/*2nd Row of Cards*/}
                    <div className="three-to-one">
                        <Link to="/visas" reloadDocument={true}>
                            <img className="home-icon"
                                 src={require('./Home/card-images/card4.png')}
                                 alt=""/>
                            <div className='home-rt home-padding'>
                                <h2 className="home-text">VISAS & PERMITS </h2>
                                <p className="home-text">Navigate visa requirements, work permits, and legalities of
                                    working remotely abroad.
                                </p>
                            </div>
                        </Link>
                        <Link to="/community" reloadDocument={true}>
                            <img className="home-icon"
                                 src={require('./Home/card-images/card5.png')}
                                 alt=""/>
                            <div className='home-rt home-padding'>
                                <h2 className="home-text">BUILDING YOUR COMMUNITY</h2>
                                <p className="home-text">Connect with fellow digital nomads and find support
                                    networks.</p>
                            </div>
                        </Link>
                        <Link to="/fitness" reloadDocument={true}>
                            <img className="home-icon"
                                 src={require('./Home/card-images/card6.png')}
                                 alt=""/>
                            <div className='home-rt home-padding'>
                                <h2 className="home-text">STAYING FIT ON THE ROAD</h2>
                                <p className="home-text">Thrive Anywhere: Travel health tips and fitness routines for
                                    every destination.</p>
                            </div>
                        </Link>
                    </div>
                    <ChatBot
                        position={"robot-icon-position"}
                        scale={"robot robot-scale-icon"}
                        gradient={"robot-radial-gradient-none"}
                        robotText={"robot-text-none"}
                    />
                </div>
                <Footer/>
            </div>
        </>
    )
}

export default Home
