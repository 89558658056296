import React, {useState} from "react"
import './GPT.css'
import '../Chatbot/chatbot.css'
import ChatBot from "../Chatbot/Chatbot";
import Footer from "../Footer"

const API_KEY = process.env.REACT_APP_API_KEY;

function Community() {
    const [userResponses, setUserResponses] = useState({
        destinationCountry: "",
        additionalInformation: ""
    });

    const [aiResponse, setAiResponse] = useState("");

    document.body.style.backgroundColor = '#FCD9C4';
    async function callOpenAIAPI() {
        const {
            destinationCity,
            additionalInformation
        } = userResponses;

        const prompt = `Based on the following responses:\n
        1. Destination City: ${destinationCity}\n
        2. Additional Information: ${additionalInformation}\n
        In 5-6 complete, brief, factual sentences, discuss the kinds of people who are there, suggest places where digital nomads or travelers meet up, co-working spaces if available, and online communities that are specific to the destination.`;

        const APIBody = {
            "model": "gpt-3.5-turbo-instruct",
            "prompt": prompt,
            "temperature": 0.7,
            "max_tokens": 350,
            "top_p": 0.7,
            "frequency_penalty": 0.2,
            "presence_penalty": 0.1
        };

        await fetch("https://api.openai.com/v1/completions", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + API_KEY
            },
            body: JSON.stringify(APIBody)
        }).then((data) => {
            return data.json();
        }).then((data) => {
            console.log(data);
            setAiResponse(data.choices[0].text.trim());
        });
    }

    const handleChange = (question, value) => {
        setUserResponses(prevState => ({
            ...prevState,
            [question]: value
        }));
    };

    return (
        <>
            <div className="community-background-image"></div>
            <div className='max1024'>
                <section className="AI-container" style={{textAlign: 'center'}}>
                    <div className="AI-page-title" style={{color: 'fff'}}>Make Connections</div>
                    <div className="AI-cat-descriptions discover">Let's help you discover online communities, local
                        meetups, and the kinds of people you can meet at your destination as a digital nomad.
                    </div>

                    <div>
                        <p className="AI-questions discover"><strong>Destination City:</strong> Where will you be
                            traveling to?</p>
                        <input className='AI-input discover'
                               onChange={(e) => handleChange('destinationCity', e.target.value)}
                               placeholder='Destination city or country...'
                        />
                    </div>

                    <div>
                        <p className="AI-questions discover"><strong>Additional Information:</strong> Is there any
                            specific information or concerns you would like to share regarding your interests or
                            preferences for meetups and communities?</p>
                        <input className='AI-input discover'
                               onChange={(e) => handleChange('additionalInformation', e.target.value)}
                               placeholder='Additional information...'
                        />
                    </div>

                    <div>
                        <button className="AI-btn-discover" onClick={callOpenAIAPI}>Recommendations</button>
                        {aiResponse !== "" ?
                            <p className="AI-summary discover">{aiResponse}</p>
                            :
                            null
                        }
                    </div>
                </section>
                <ChatBot
                    position={"robot-icon-position"}
                    scale={"robot robot-scale-icon"}
                    gradient={"robot-radial-gradient-none"}
                    robotText={"robot-text-none"}
                />
                <Footer/>
            </div>
        </>
    )
}

export default Community;