import React, {useState} from "react"
import './GPT.css'
import '../Chatbot/chatbot.css'
import ChatBot from "../Chatbot/Chatbot";
import Footer from "../Footer"

const API_KEY = process.env.REACT_APP_API_KEY;

function Visas() {
    const [userResponses, setUserResponses] = useState({
        nationality: "",
        destinationCountry: "",
        purposeOfStay: "",
        durationOfStay: "",
        employmentStatus: "",
        financialResources: "",
        additionalInformation: ""
    });

    const [aiResponse, setAiResponse] = useState("");

    document.body.style.backgroundColor = '#CBD5C0';

    async function callOpenAIAPI() {
        const {
            nationality,
            destinationCountry,
            purposeOfStay,
            durationOfStay,
            previousTravelHistory,
            familyAccompanying,
            employmentStatus,
            financialResources,
            additionalInformation
        } = userResponses;

        const prompt = `Based on the following responses:\n
        1. Nationality: ${nationality}\n
        2. Destination Country: ${destinationCountry}\n
        3. Purpose of Stay: ${purposeOfStay}\n
        4. Duration of Stay: ${durationOfStay}\n
        5. Previous Travel History: ${previousTravelHistory}\n
        6. Family Accompanying: ${familyAccompanying}\n
        7. Employment Status: ${employmentStatus}\n
        8. Financial Resources: ${financialResources}\n
        9. Additional Information: ${additionalInformation}\n
        In concise and complete sentences, provide your suggestions for managing visas and permits.`;

        const APIBody = {
            "model": "gpt-3.5-turbo-instruct",
            "prompt": prompt,
            "temperature": 0.7,
            "max_tokens": 350,
            "top_p": 0.7,
            "frequency_penalty": 0.2,
            "presence_penalty": 0.1
        };

        await fetch("https://api.openai.com/v1/completions", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + API_KEY
            },
            body: JSON.stringify(APIBody)
        }).then((data) => {
            return data.json();
        }).then((data) => {
            console.log(data);
            setAiResponse(data.choices[0].text.trim());
        });
    }

    const handleChange = (question, value) => {
        setUserResponses(prevState => ({
            ...prevState,
            [question]: value
        }));
    };

    return (
        <>
            <div className="visas-background-image"></div>

            <div className='max1024'>
                <section className="AI-container" style={{textAlign: 'center'}}>
                    <div className="AI-page-title" style={{color: 'fff'}}>Visas & Permits</div>
                    <div className="AI-cat-descriptions">Let's help you navigate visa and permit requirements for your
                        next destination.
                    </div>

                    {/* Input fields for each question */}
                    <div>
                        <p className="AI-questions"><strong>Nationality:</strong> What is your nationality?</p>
                        <input className='AI-input'
                               onChange={(e) => handleChange('nationality', e.target.value)}
                               placeholder='Your nationality...'
                        />
                    </div>
                    <div>
                        <p className="AI-questions"><strong>Destination Country:</strong> Which country are you planning
                            to visit?</p>
                        <input className='AI-input'
                               onChange={(e) => handleChange('destinationCountry', e.target.value)}
                               placeholder='Destination country...'
                        />
                    </div>
                    <div>
                        <p className="AI-questions"><strong>Purpose of Stay:</strong> What is the purpose of your stay?
                        </p>
                        <input className='AI-input'
                               onChange={(e) => handleChange('purposeOfStay', e.target.value)}
                               placeholder='Work, tourism, study...'
                        />
                    </div>

                    <div>
                        <p className="AI-questions"><strong>Duration of Stay:</strong> How long do you plan to stay? Please specify the approximate duration of your stay.</p>
                        <input className='AI-input'
                               onChange={(e) => handleChange('durationOfStay', e.target.value)}
                               placeholder='Duration of stay...'
                        />
                    </div>

                    <div>
                        <p className="AI-questions"><strong>Employment Status:</strong> Are you currently employed,
                            self-employed, or unemployed? Please briefly describe your employment situation.</p>
                        <input className='AI-input'
                               onChange={(e) => handleChange('employmentStatus', e.target.value)}
                               placeholder='Your employment status...'
                        />
                    </div>

                    <div>
                        <p className="AI-questions"><strong>Financial Resources:</strong> Please provide an estimate of
                            your monthly income, savings, and any additional financial assets or funding sources
                            available to support your stay in [Destination Country].</p>
                        <input className='AI-input'
                               onChange={(e) => handleChange('financialResources', e.target.value)}
                               placeholder='Your financial resources...'
                        />
                    </div>

                    <div>
                        <p className="AI-questions"><strong>Additional Information:</strong> Is there any other
                            information or specific concerns you would like to share regarding your visa and permit
                            requirements?</p>
                        <input className='AI-input'
                               onChange={(e) => handleChange('additionalInformation', e.target.value)}
                               placeholder='Additional information...'
                        />
                    </div>

                    <div>
                        <button className="AI-btn-discover" onClick={callOpenAIAPI}>Get Visa and Permit
                            Recommendations
                        </button>
                        {aiResponse !== "" ?
                            <p className="AI-summary">{aiResponse}</p>
                            :
                            null
                        }
                    </div>
                </section>
                <ChatBot
                    position={"robot-icon-position"}
                    scale={"robot robot-scale-icon"}
                    gradient={"robot-radial-gradient-none"}
                    robotText={"robot-text-none"}
                />
                <Footer/>
            </div>
        </>
    )
}

export default Visas;