import React from "react";
import {createChatBotMessage} from "react-chatbot-kit";
import LinkList from "./LinkList/LinkList";

const config = {
    botName: "Aaron",
    initialMessages: [
        createChatBotMessage("Hi! Do you want to...",
            {
                widget: "intro",
            }),

    ],

    customStyles: {
        botMessageBox: {
            backgroundColor: "#a3b2bd",
        },
        chatButton: {
            backgroundColor: "#a3b2bd",
        },
    },

    widgets: [
        {
            widgetName: "intro",
            widgetFunc: (props) => <LinkList {...props} />,
            props: {
                options: [
                    {
                        text: "find ideal places to live",
                        url: "/discover",
                        id: 1,
                    },
                    {
                        text: "explore top nomad hotspots",
                        url: "/top-cities",
                        id: 2,
                    },
                    {
                        text: "check living costs",
                        url: "/costs",
                        id: 3,
                    },
                    {
                        text: "visas",
                        url: "/visas",
                        id: 4,
                    },
                ],
            },
        },

        {
            widgetName: "GPTHelpLinks",
            widgetFunc: (props) => <LinkList {...props} />,
            props: {
                options: [
                    {
                        text: "discover your place",
                        url: "/discover",
                        id: 1,
                    },
                    {
                        text: "top destinations",
                        url: "/top-cities",
                        id: 2,
                    },
                    {
                        text: "cost of living",
                        url: "/costs",
                        id: 3,
                    },
                    {
                        text: "visas",
                        url: "/visas",
                        id: 4,
                    },
                    {
                        text: "fitness",
                        url: "/fitness",
                        id: 5,
                    },
                    {
                        text: "community",
                        url: "/community",
                        id: 6,
                    },
                ],
            },
        },

        {
            widgetName: "discoverPlace",
            widgetFunc: (props) => <LinkList {...props} />,
            props: {
                options: [
                    {
                        text: "discover places tailored to your needs",
                        url:
                            "/discover",
                        id: 1,
                    }
                ],
            },
        },

        {
            widgetName: "topCities",
            widgetFunc: (props) => <LinkList {...props} />,
            props: {
                options: [
                    {
                        text: "30 cities digital nomads love",
                        url:
                            "/top-cities",
                        id: 1,
                    }
                ],
            },
        },

        {
            widgetName: "costs",
            widgetFunc: (props) => <LinkList {...props} />,
            props: {
                options: [
                    {
                        text: "find the cost to live elsewhere",
                        url:
                            "/costs",
                        id: 1,
                    }
                ],
            },
        },
        {
            widgetName: "visas",
            widgetFunc: (props) => <LinkList {...props} />,
            props: {
                options: [
                    {
                        text: "visa & permit regulations",
                        url:
                            "/visas",
                        id: 1,
                    }
                ],
            },
        },
        {
            widgetName: "fitness",
            widgetFunc: (props) => <LinkList {...props} />,
            props: {
                options: [
                    {
                        text: "health & fitness",
                        url:
                            "/fitness",
                        id: 1,
                    }
                ],
            },
        },
        {
            widgetName: "community",
            widgetFunc: (props) => <LinkList {...props} />,
            props: {
                options: [
                    {
                        text: "How to connect with others abroad",
                        url:
                            "/community",
                        id: 1,
                    }
                ],
            },
        },
        {
            widgetName: "bye",
            widgetFunc: (props) => <LinkList {...props} />,
            props: {
                options: [
                    {
                        text: "See you soon!",
                    }
                ],
            },
        },
        {
            widgetName: "unsure",
            widgetFunc: (props) => <LinkList {...props} />,
            props: {
                options: [
                    {
                        text: "find ideal places to live",
                        url: "/discover",
                        id: 1,
                    },
                    {
                        text: "explore top nomad hotspots",
                        url: "/top-cities",
                        id: 2,
                    },
                    {
                        text: "check living costs",
                        url: "/costs",
                        id: 3,
                    },
                    {
                        text: "visas and legal requirements",
                        url: "/visas",
                        id: 4,
                    },
                ],
            },
        },


    ],
};

export default config;
