import React, {useState} from "react"
import './GPT.css'
import '../Chatbot/chatbot.css'
import ChatBot from "../Chatbot/Chatbot";
import Footer from "../Footer"

const API_KEY = process.env.REACT_APP_API_KEY;

function Fitness() {
    const [userResponses, setUserResponses] = useState({
        destinationCity: "",
        additionalInformation: ""
    });

    const [aiResponse, setAiResponse] = useState("");
    document.body.style.backgroundColor = '#C1CCD5';

    async function callOpenAIAPI() {
        const {
            destinationCity,
            additionalInformation
        } = userResponses;

        const prompt = `Based on the following responses:\n
        1. Destination City: ${destinationCity}\n
        2. Additional Information: ${additionalInformation}\n
        In complete and concise sentences, give fitness recommendations specific to a trip to ${destinationCity} + health tips for staying fit while traveling.`;

        const APIBody = {
            "model": "gpt-3.5-turbo-instruct",
            "prompt": prompt,
            "temperature": 0.7,
            "max_tokens": 300,
            "top_p": 0.7,
            "frequency_penalty": 0.2,
            "presence_penalty": 0.1
        };

        await fetch("https://api.openai.com/v1/completions", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + API_KEY
            },
            body: JSON.stringify(APIBody)
        }).then((data) => {
            return data.json();
        }).then((data) => {
            console.log(data);
            setAiResponse(data.choices[0].text.trim());
        });
    }

    const handleChange = (question, value) => {
        setUserResponses(prevState => ({
            ...prevState,
            [question]: value
        }));
    };

    return (
        <>
            <div className="fitness-background-image"></div>

            <div className='max1024'>
                <section className="AI-container" style={{textAlign: 'center'}}>
                    <div className="AI-page-title" style={{color: 'fff'}}>Health & Fitness</div>
                    <div className="AI-cat-descriptions">Tailor a health and fitness plan to optimize your well-being
                        while traveling.
                    </div>

                    <div>
                        <p className="AI-questions"><strong>Destination City:</strong> Which city will you be
                            visiting?</p>
                        <input className='AI-input'
                               onChange={(e) => handleChange('destinationCity', e.target.value)}
                               placeholder='Destination city...'
                        />
                    </div>

                    <div>
                        <p className="AI-questions"><strong>Additional Information:</strong> Is there any specific
                            information you would like to share regarding your health or fitness activities you'd like
                            to enjoy during your trip?</p>
                        <input className='AI-input'
                               onChange={(e) => handleChange('additionalInformation', e.target.value)}
                               placeholder='Additional information...'
                        />
                    </div>

                    <div>
                        <button className="AI-btn-discover" onClick={callOpenAIAPI}>Fitness Recommendations</button>
                        {aiResponse !== "" ?
                            <p className="AI-summary">{aiResponse}</p>
                            :
                            null
                        }
                    </div>
                </section>
                <ChatBot
                    position={"robot-icon-position"}
                    scale={"robot robot-scale-icon"}
                    gradient={"robot-radial-gradient-none"}
                    robotText={"robot-text-none"}
                />
                <Footer/>
            </div>
        </>
    )
}

export default Fitness;
