import './App.css';
import MobileNav from './components/Menu'
import Home from './components/Home'
import TopCities from "./components/AllCities/TopCities";
import Discover from "./components/GPT/Discover";
import Costs from "./components/GPT/Costs";
import Visas from "./components/GPT/Visas"
import Fitness from "./components/GPT/Fitness"
import Community from "./components/GPT/Community"
import About from "./components/About/About"
import {BrowserRouter, Routes, Route} from "react-router-dom";

function App() {
    return (
        <BrowserRouter>
            <div className="App">
                <MobileNav/>
                <Routes>
                    <Route path="/" exact element={<Home/>}/>
                    <Route  path="/discover" exact element={<Discover/>}/>
                    <Route path="/top-cities" exact element={<TopCities/>}/>
                    <Route  path="/about" exact element={<About/>}/>
                    {/*AI Help Pages*/}
                    <Route  path="/costs" exact element={<Costs/>}/>
                    <Route  path="/visas" exact element={<Visas/>}/>
                    <Route  path="/fitness" exact element={<Fitness/>}/>
                    <Route  path="/community" exact element={<Community/>}/>

                </Routes>
            </div>
        </BrowserRouter>
    );
}


export default App;