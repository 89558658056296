class MessageParser {
    constructor(actionProvider, state) {
        this.actionProvider = actionProvider;
        this.state = state;
    }
    parse(message) {
        const lowercase = message.toLowerCase();
        if (lowercase.includes("hello") || lowercase.includes("hey")) {
            this.actionProvider.greet();
        } else if (lowercase.includes("affordable") || lowercase.includes("find a great place") || lowercase.includes("nomad-friendly") || lowercase.includes("warm") || lowercase.includes("beach") || lowercase.includes("small town") || lowercase.includes("city") || lowercase.includes("short-term") || lowercase.includes("long-term") || lowercase.includes("language schools") || lowercase.includes("healthcare") || lowercase.includes("outdoor") || lowercase.includes("city") || lowercase.includes("retreats") || lowercase.includes("laptop friendly") || lowercase.includes("remote jobs") || lowercase.includes("location") || lowercase.includes("work-life balance") || lowercase.includes("explore") || lowercase.includes("ideal spots") || lowercase.includes("perfect places") || lowercase.includes("search destinations") || lowercase.includes("find locations")) {
            this.actionProvider.discoverPlace();
        } else if (lowercase.includes("best places") || lowercase.includes("top") || lowercase.includes("ranked") || lowercase.includes("reviewed") || lowercase.includes("popular") || lowercase.includes("recommended") || lowercase.includes("rated") || lowercase.includes("rank")) {
            this.actionProvider.topCities();
        } else if (lowercase.includes("cost") || lowercase.includes("money") || lowercase.includes("price") || lowercase.includes("cheap") || lowercase.includes("expensive") || lowercase.includes("luxury") || lowercase.includes("budget")) {
            this.actionProvider.costs();
        } else if (lowercase.includes("fitness") || lowercase.includes("weight") || lowercase.includes("wellness") || lowercase.includes("sick") || lowercase.includes("workout") || lowercase.includes("routine") || lowercase.includes("exercise") || lowercase.includes("training") || lowercase.includes("burnout") || lowercase.includes("fitness") || lowercase.includes("gym") || lowercase.includes("health")) {
            this.actionProvider.fitness();
        } else if (lowercase.includes("visa") || lowercase.includes("permit") || lowercase.includes("work visa") || lowercase.includes("work permit") || lowercase.includes("immigration") || lowercase.includes("rules") || lowercase.includes("legal") || lowercase.includes("requirements") || lowercase.includes("work requirements") || lowercase.includes("relocation") || lowercase.includes("employment") || lowercase.includes("extend stay") || lowercase.includes("job")) {
            this.actionProvider.visas();
        } else if (lowercase.includes("communit") || lowercase.includes("meet") || lowercase.includes("friend") || lowercase.includes("cafe") || lowercase.includes("join") || lowercase.includes("connect") || lowercase.includes("work groups") || lowercase.includes("co-working")) {
            this.actionProvider.community();
        } else if (lowercase.includes("bye") || lowercase.includes("finish") || lowercase.includes("done")) {
            this.actionProvider.bye();
        } else if (lowercase.includes("no") || lowercase.includes("not really")) {
            this.actionProvider.no();
        } else if (lowercase.includes("not sure") || lowercase.includes("help") || lowercase.includes("/.*/") || lowercase.includes("other") || lowercase.includes("")) {
            this.actionProvider.unsure();
        } else {
            this.actionProvider.unsure();
        }
    }
}

export default MessageParser;
