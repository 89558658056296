import React, {useState} from "react"
import './GPT.css'
import '../Chatbot/chatbot.css'
import ChatBot from "../Chatbot/Chatbot";
import Footer from "../Footer"

const API_KEY = process.env.REACT_APP_API_KEY;

function Discover() {
    const [userResponses, setUserResponses] = useState({
        budget: "",
        workEnvironment: "",
        activities: "",
        internetConnection: "",
        stayDuration: "",
        communityPreference: "",
        climatePreference: "",
        languagePreference: "",
        safetyPreference: ""
    });

    const [aiResponse, setAiResponse] = useState("");

    document.body.style.backgroundColor = '#FBE3D4';

    async function callOpenAIAPI() {
        const {
            budget,
            workEnvironment,
            activities,
            internetConnection,
            stayDuration,
            communityPreference,
            climatePreference,
            languagePreference,
            safetyPreference
        } = userResponses;

        const prompt = `Based on the following responses:\n
        1. Budget: ${budget}\n
        2. Work Environment: ${workEnvironment}\n
        3. Activities: ${activities}\n
        4. Internet Connection: ${internetConnection}\n
        5. Stay Duration: ${stayDuration}\n
        6. Community Preference: ${communityPreference}\n
        7. Climate Preference: ${climatePreference}\n
        8. Language Preference: ${languagePreference}\n
        9. Safety Preference: ${safetyPreference}\n
        In concise and complete sentences, provide your top 3 suggestions for where the digital nomad should live. Place the city and country in all CAPS the first time you list it. `;

        const APIBody = {
            "model": "gpt-3.5-turbo-instruct",
            "prompt": prompt,
            temperature: .6,
            max_tokens: 350,
            top_p: .7,
            frequency_penalty: .2,
            presence_penalty: 0.1,
        };

        await fetch("https://api.openai.com/v1/completions", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + API_KEY
            },
            body: JSON.stringify(APIBody)
        }).then((data) => {
            return data.json();
        }).then((data) => {
            console.log(data);
            setAiResponse(data.choices[0].text.trim());
        });
    }

    const handleChange = (question, value) => {
        setUserResponses(prevState => ({
            ...prevState,
            [question]: value
        }));
    };

    return (
        <>
            <div className="discover-background-image"></div>

            <div className='max1024'>
                <section className="AI-container" style={{textAlign: 'center'}}>
                    <div className="AI-page-title" style={{color: 'fff'}}>Discover Your Place</div>
                    <div className="AI-cat-descriptions discover">Dream of working from a beach in Bali or a cozy cafe
                        in Paris? With the power of AI, we'll help you find the perfect spot to live and work remotely.
                    </div>

                    {/* Input fields for each question */}
                    <div>
                        <p className="AI-questions discover"><strong>Budget: </strong> What is your monthly budget for
                            living
                            expenses?</p>
                        <input className='AI-input discover'
                               onChange={(e) => handleChange('budget', e.target.value)}
                               placeholder='Give a budget estimate...'
                        />
                    </div>
                    <div>
                        <p className="AI-questions discover"><strong>Work Style: </strong>Do you work best in a shared
                            co-working environment with others, or do you prefer the complete quiet and privacy of your
                            own apartment for work?</p>
                        <input className='AI-input discover'
                               onChange={(e) => handleChange('workEnvironment', e.target.value)}
                               placeholder='Your ideal work environment...'
                        />
                    </div>
                    <div>
                        <p className="AI-questions discover"><strong>Activities:</strong> What activities are important
                            for
                            your
                            well-being outside of work? </p>
                        <input className='AI-input discover'
                               onChange={(e) => handleChange('activities', e.target.value)}
                               placeholder='List activities... '
                        />
                    </div>

                    <div>
                        <p className="AI-questions discover"><strong>Internet Needs:</strong> Does your work require a
                            blazing-fast
                            and reliable internet connection? </p>
                        <input className='AI-input discover'
                               onChange={(e) => handleChange('internetConnection', e.target.value)}
                               placeholder='Blazing fast wifi or anything will do..'
                        />
                    </div>
                    <div>
                        <p className="AI-questions discover"><strong>Visa Requirements:</strong> How long would you like
                            to
                            stay at
                            your next location? </p>
                        <input className='AI-input discover'
                               onChange={(e) => handleChange('stayDuration', e.target.value)}
                               placeholder='A week or 90 day minimum?'
                        />
                    </div>
                    <div>
                        <p className="AI-questions discover"><strong>Social Scene:</strong> Are you looking for a
                            vibrant
                            expat
                            community or a more local experience? </p>
                        <input className='AI-input discover'
                               onChange={(e) => handleChange('communityPreference', e.target.value)}
                               placeholder='Live like an expat or a local?'
                        />
                    </div>
                    <div>
                        <p className="AI-questions discover"><strong>Language Barriers:</strong> Are you comfortable
                            navigating a new
                            language, or would a primarily English-speaking location be a priority? </p>
                        <input className='AI-input discover'
                               onChange={(e) => handleChange('languagePreference', e.target.value)}
                               placeholder='English only?'
                        />
                    </div>
                    <div>
                        <p className="AI-questions discover"><strong>Climate Preference:</strong> Do you crave sunshine
                            and
                            beaches,
                            or prefer a cooler climate with distinct seasons? </p>
                        <input className='AI-input discover'
                               onChange={(e) => handleChange('climatePreference', e.target.value)}
                               placeholder='Beach, mountain, other... '
                        />
                    </div>
                    <div>
                        <p className="AI-questions discover"><strong>Safety:</strong> How important is feeling safe and
                            secure in
                            your chosen location? </p>
                        <input className='AI-input discover'
                               onChange={(e) => handleChange('safetyPreference', e.target.value)}
                               placeholder='Safety...'
                        />
                    </div>
                    <div>
                        <button className="AI-btn-discover" onClick={callOpenAIAPI}>Discover Your Place</button>
                        {aiResponse !== "" ?
                            <p className="AI-summary discover">{aiResponse}</p>
                            :
                            null
                        }
                    </div>
                </section>
                <ChatBot
                    position={"robot-icon-position"}
                    scale={"robot robot-scale-icon"}
                    gradient={"robot-radial-gradient-none"}
                    robotText={"robot-text-none"}
                />
                <Footer/>
            </div>
        </>
    )
}

export default Discover;