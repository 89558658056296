import * as React from "react"
import '../index.css'
import {slide as Menu} from 'react-burger-menu'
import {Link} from "react-router-dom";
import "../App.css"

var styles = {
    bmBurgerButton: {
        position: 'fixed',
        width: '30px',
        height: '20px',
        right: '36px',
        top: '26px',

    },

    bmBurgerBars: {
        background: '#7B717A',
        height: '1.5px',

    },

    bmCrossButton: {
        top: '22px',
        right: '38px',
        height: '28px',
        width: '28px'
    },
    bmCross: {
        height: '22px',
        width: '1px',
        background: '#fff'
    },
    bmMenuWrap: {
        position: 'fixed',
        height: '100%',
        width: '481px',
        top: 0,
        zIndex: 8000,

    },
    bmMenu: {
        background: 'rgba(0,6,10,0.88)',
        padding: '2.5em 1.5em 0',
        fontSize: '1.25em',
        fontFamily: 'Poppins-Light',
        lineHeight: "2.4em",
        textTransform: 'upperCase',
    },

    bmMorphShape: {
        fill: '#373a47'
    },
    bmItemList: {
        color: '#b8b7ad',
        padding: '0.8em'
    },
    bmItem: {
        display: 'flex',
        flexDirection: 'columns',
        color: '#fff'
    },
    bmOverlay: {
        background: 'transparent'
    }
}


const MobileNav = () => {
    const showSettings = (event) => {
        event.preventDefault();
    }

    return (
        <Menu class="mobileNav" right styles={styles}>
            <Link to="/" reloadDocument={true}>Home </Link>
            <Link to="/discover" reloadDocument={true}>Discover Your Place</Link>
            <Link to="/top-cities" reloadDocument={true}>Top Destinations</Link>
            <Link to="/costs" reloadDocument={true}>Costs</Link>
            <Link to="/visas" reloadDocument={true}>Visas</Link>
            <Link to="/fitness" reloadDocument={true}>Fitness</Link>
            <Link to="/community" reloadDocument={true}>Community</Link>
            <Link to="/about" reloadDocument={true}>About</Link>
        </Menu>
    );
}

export default MobileNav