import {createChatBotMessage} from "react-chatbot-kit";

class ActionProvider {
    constructor(createChatBotMessage, setStateFunc) {
        this.createChatBotMessage = createChatBotMessage;
        this.setState = setStateFunc;
    }

    // new method

    greet() {
        const greetingMessage = this.createChatBotMessage("How can I help you?");
        this.updateChatbotState(greetingMessage);
    }

    unsure = () => {
        const learningMessage = this.createChatBotMessage(
            "Here are some topics you may find helpful.",
            {
                widget: "unsure",
            },
            createChatBotMessage("If what you need isn't listed, tell me exactly what you're looking for?"),
        );

        this.updateChatbotState(learningMessage);
    };

    no() {
        const message = this.createChatBotMessage(
            "Here are some helpful links.",
            {
                widget: "GPTHelpLinks",
            }
        );
        this.updateChatbotState(message);
    };

    discoverPlace = () => {
        const message = this.createChatBotMessage(
            "Let's plan your next adventure!",
            {
                widget: "discoverPlace",
            }
        );
        this.updateChatbotState(message);
    };

    topCities = () => {
        const message = this.createChatBotMessage(
            "Browse the top destinations!",
            {
                widget: "topCities",
            }
        );
        this.updateChatbotState(message);
    };

    costs = () => {
        const message = this.createChatBotMessage(
            "Here's some information about costs",
            {
                widget: "costs",
            }
        );
        this.updateChatbotState(message);
    };
    visas = () => {
        const message = this.createChatBotMessage(
            "Review legal requirements to ensure a smooth relocation",
            {
                widget: "visas",
            }
        );
        this.updateChatbotState(message);
    };
    fitness = () => {
        const message = this.createChatBotMessage(
            "Here you go...",
            {
                widget: "fitness",
            }
        );
        this.updateChatbotState(message);
    };
    community = () => {
        const message = this.createChatBotMessage(
            "Uncover your destination's social side",
            {
                widget: "community",
            }
        );
        this.updateChatbotState(message);
    };
    bye = () => {
        const message = this.createChatBotMessage(
            "Thank you for the chat! Let me know if you need anything else.",
            {
                widget: "bye",
            }
        );
        this.updateChatbotState(message);
    };

    updateChatbotState(message) {
        // NOTICE: This function is set in the constructor, and is passed in from the top level Chatbot component. The setState function here actually manipulates the top level state of the Chatbot, so it's important that we make sure that we preserve the previous state.

        this.setState((prevState) => ({
            ...prevState,
            messages: [...prevState.messages, message],
        }));
    }
}

export default ActionProvider;
