import React from "react";
import ChatBot from "../Chatbot/Chatbot";
import './about.css'
import Footer from "../Footer"


const About = () => {

    return (
        <>
            <div className="writingcontainer">
                <div className="map-image"></div>
                <div className="outline1024">
                    <p className="about-title">ABOUT</p>
                    <p><strong>Work Anywhere, Live Everywhere </strong> empowers you
                        to thrive as a digital nomad. In-depth resources and practical tools
                        help you work remotely from anywhere in the world. Whether you're a seasoned adventurer or
                        just starting out, we'll equip you for success. Find your ideal nomad base and navigate the
                        world as a digital professional. Explore our content and
                        join the thriving digital nomad community! If you have
                        suggestions for improvements to the site or just want to connect, reach out to
                        ambrown16706@gmail.com.
                    </p>
                </div>
            </div>
            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
            <Footer/>
        </>
    )
}

export default About