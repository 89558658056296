import React from "react";
import Chatbot from "react-chatbot-kit";
import "./chatbot.css";
import ActionProvider from "./ActionProvider";
import MessageParser from "./MessageParser";
import config from "./config";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

function ChatBot({position, scale, gradient, robotText, instructions}) {
    return (
        <>
            <Popup trigger={
                <div className={position}>
                    <div className={gradient}>
                        <div className={scale}>
                            <div className="circle-bg">
                                <div className="robot-ear left"></div>
                                <div className="robot-head">
                                    <div className="robot-face">
                                        <div className="eyes left"></div>
                                        <div className="eyes right"></div>
                                        <div className="mouth"></div>
                                    </div>
                                </div>
                                <div className="robot-ear right"></div>
                                <div className="robot-body"></div>
                            </div>
                        </div>
                    </div>

                    <div className={robotText}><p>{instructions}</p>
                    </div>
                </div>}>

                <Chatbot
                    config={config}
                    actionProvider={ActionProvider}
                    messageParser={MessageParser}

                />

            </Popup>
        </>
    );
}

export default ChatBot;
